<template>
    <div class="personal-address">
        <div class="address-title">
            <div class="back" @click="backClick">
                <img src="@/assets/images/personal/z.png" />
                <span>Back</span>
            </div>
            <h5>Shipping Address</h5>
        </div>
        <div class="address-main" v-show="addressEmpty">
            <div class="main-item" v-for="(item, index) in addressList" :key="index">
                <h5>{{ item.name }}</h5>
                <span>{{ item.telephone }}</span>
                <p>{{ item.full_address }}</p>
                <div>
                    <el-radio v-if="item.is_default != 1" v-model="Default" :label="index + 2" >Default</el-radio>
                    <el-radio v-else-if="item.is_default == 1" v-model="Default" :label="item.is_default" >Default</el-radio>
                    <div>
                        <img src="@/assets/images/personal/delet.png" @click="deleteAddressClick(index)" />
                        <img src="@/assets/images/personal/edit.png" @click="editClick(index)" />
                    </div>
                </div>
            </div>
        </div>

        <div class="address-empty" v-show="!addressEmpty">
            <img src="@/assets/images/personal/addressEmpty.png" />
            <span>No adress added yet</span>
        </div>
        <button @click="addClick"></button>
        <el-dialog :title="addressTitle" :visible.sync="addressForm" @closed="handleClose">
            <AddressForm :addressAtive="ative" :addressList="addressFormList" @closeDialog="handleClose"></AddressForm>
        </el-dialog>
        <el-dialog :visible.sync="deleteAddress" @closed="handleClose1" width="23%" style="margin-top: 20vh">
            <DeleteAddress @deteleNO="deleteNo" @deteleYes="deleteYes" :ids="ids"></DeleteAddress>
        </el-dialog>
    </div>
</template>

<style>
.about-content{
    background: #fff;
}
</style>
<style lang="scss" scoped>
@import "./css/address.scss";
</style>

<script>
import address from "./js/address.js"
import AddressForm from "./alert/addressForm"
import DeleteAddress from "./alert/deleteAddress"

export default {
    name: "address",
    components: {
        AddressForm,
        DeleteAddress
    },
    mixins: [address]
}
</script>