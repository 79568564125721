import {
    addressList
} from "@/api/member/member"

export default {
    name: "address",
    components: {

    },
    data: () => {
        return {
            Default: 1,
            addressEmpty: false,
            addressList: [],
            addressFormList: {},
            addressTitle: "",
            addressForm: false,
            deleteAddress: false,
            ative: 0,
            ids: 0
        }
    },
    watch: {
        '$route': 'fetchData'
    },
    created() {

    },
    mounted() {
        if (this.addressList.length <= 0) {
            this.addressEmpty = true;
        } else {
            this.addressEmpty = false;
        }
        this.getMemberAddress();
    },
    computed: {

    },
    methods: {
        //获取收货地址
        getMemberAddress() {
            addressList({
                page_size: 0
            }).then(res => {
                const {
                    code,
                    message,
                    data
                } = res
                if (data && data.list) {
                    // console.log(data)
                    this.addressList = data.list;
                    // console.log(this.addressList)
                    this.addressEmpty = true;
                }
            }).catch(err => {
                const {
                    code,
                    message,
                    data
                } = err
                this.$message.error(message)
            })
        },
        fetchData() {
            if (this.addressList.length <= 0) {
                this.addressEmpty = true;
            } else {
                this.addressEmpty = false;
            }
            // this.$forceUpdate();
        },
        handleClose() {
            this.addressForm = false;
            this.getMemberAddress();
            // this.$forceUpdate();
        },
        handleClose1() {
            this.deleteAddress = false;
        },
        addClick() {
            this.addressTitle = "Add New Address";
            this.addressForm = true;
            this.ative = 0;
            this.addressFormList = [];
        },
        editClick(i) {
            this.addressTitle = "Edit Address";
            this.addressForm = true;
            this.ative = 1;
            this.addressFormList = this.addressList[i];
        },
        deleteAddressClick(i) {
            this.ids = this.addressList[i].id;
            this.deleteAddress = true;
        },
        deleteNo(val) {
            this.deleteAddress = val;
        },
        deleteYes(val) {
            this.deleteAddress = val;
            this.getMemberAddress();
        },
        backClick() {
            this.$router.go(-1);
        }
    }
}
