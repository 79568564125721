<template>
    <div class="delete">
        <h5>Confirm delete this address</h5>
        <div>
            <button class="no" @click="noClick">NO</button>
            <button class="yes" @click="yesClick">YES</button>
        </div>
    </div>
</template>

<script>
import { memberaddressDelete } from "@/api/pc"

export default {
    name: "deleteAddress",
	components: {

	},
	data: () => {
		return {
            delete: false
		}
	},
    props: ["ids"],
	methods: {
        noClick() {
            this.$emit("deteleNO", this.delete);
        },
        yesClick() {
             memberaddressDelete({ id: this.ids })
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        this.$message({ message: "Deleted successfully", type: "success" });
                        this.$emit("deteleYes", this.delete);
                    }
                })
                .catch((err) => {
                    // console.log(err)
                    this.$message.error(err.message)
                })
        }
	}
}
</script>


<style lang="scss" scoped>
.delete {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    h5 {
        font-size: 14px;
        font-family: Montserrat,SF-UI;
        // font-weight: 400;
        color: #330000;
        padding-bottom: 40px;
    }
    > div {
        display: flex;
        justify-content: space-between;

        > button {
            width: calc(50% - 11px);
            outline: none;
            border: none;
            cursor: pointer;
            padding: 15px 40px;
            font-size: 16px;
            font-family: Montserrat,SF-UI;
            font-weight: bold;
            border: 1px solid #330000;
        }
        .no{
            background: #330000;
            color: #FFFFFF;
        }
        .yes{
            background: #fff;
            color: #330000;
        }
    }
}
</style>